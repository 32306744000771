<template>
    <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Systems</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex" @click="filterOpen" :class="systemData.length == 0?'btndisabled pointer-events-none':'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <div class="text-center">
                      <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                   </div>
                </div>
            </div>
        </div>
        <div class="mt-3 fontSizeInPage">
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>
        
        <newTable :tableRowData="systemData" :headersData="headers1" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>
        <div v-if="toolTipActive">
            <tool-tip :tipText="toolTipText"></tool-tip>
        </div>

        
        <popup v-if="popUp==true" :popUp="popUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>System</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <!-- :disabled="!permissions[1]" -->
                <div>
                <div>
                    <div class="statuscontainer popupbodycontainer">
                        <div class="formcontainer flex flex-col">
                            <div class="w-full upperspacing">
                                    <label class="controllabel">System Name</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="system.systemName"
                                        placeholder="System Name"
                                        :class="{
                                            'is-invalid': (v$.system.systemName.$error || checkSystemName)
                                        }"
                                    />
                                    <div v-if="v$.system.systemName.$error || checkSystemName" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.systemName.required.$invalid">Required</div>
                                        <div v-else-if="checkSystemName" class="invalid-feedback text-xs">System Name already exists.</div>
                                    </div>
                            </div>
                            <div class="w-full upperspacing">
                                    <label class="controllabel">Description</label>
                                    <textarea
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="system.description"
                                        placeholder="Description"
                                        rows="3"
                                        :class="{
                                            'is-invalid borderRed': v$.system.description.$error || descriptionExceed,
                                        }"
                                    />
                                    <div v-if="v$.system.description.$error || descriptionExceed" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.description.required.$invalid">Required</div>
                                        <div v-if="descriptionExceed" class="invalid-feedback text-xs">Description Limit is 1000 charecters</div>
                                    </div>
                            </div>
                            <div class="row flex flex-row w-full upperspacing">
                                 <div class="col-md-6 w-6/12">
                                    <label class="controllabel">Owning Entity</label>
                                    <div>
                                        <multiselect 
                                            v-model="system.ownedBy" 
                                            :options="bpOptions"
											@search-change="asyncFindBP"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Owning Entity"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.system.ownedBy.$error,
                                                'has-options': bpOptions && bpOptions.length > 0,
                                                'no-options': !bpOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No Entities Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.system.ownedBy.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.ownedBy.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                 <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Managing Entity</label>
                                    <div>
                                        <multiselect 
                                            v-model="system.managedBy" 
                                            :options="userOptionsManaged"
											@search-change="asyncFindManagedBy"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Managing Entity"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.system.managedBy.$error,
                                                'has-options': userOptionsManaged && userOptionsManaged.length > 0,
                                                'no-options': !userOptionsManaged.length &&  searchValue !== '',
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No Entities Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.system.managedBy.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.managedBy.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row grid grid-cols-2">
                                <div class="col-md-6 upperspacing">
                                    <label class="controllabel">System Owner</label>
                                    <div>
                                        <multiselect 
                                            v-model="system.owner" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                            @select="selectedSystemOwner"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="System Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.system.owner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length && searchValue !== '',
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No Members Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.system.owner.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.owner.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing leftspacing">
                                    <label class="controllabel">Job Title</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="system.jobtitle"
                                        :disabled="true"
                                        placeholder="Job Title"
                                        :class="{
                                            'is-invalid borderRed': v$.system.jobtitle.$error,
                                        }"
                                    />
                                    <div v-if="v$.system.jobtitle.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.jobtitle.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <label class="controllabel">Public Facing?</label>
                                    <select
                                        v-model="system.publicFacing"
                                       :class="{
                                              'is-invalid': v$.system.publicFacing.$error,
                                               [publicFacingFalse]: true,
                                               'inputboxstylingForSelectAtPopup': system.publicFacing === '',
                                               'stylingForInputboxstylingForSelectAtPopup': system.publicFacing !== ''
                                            }"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option class="optionColors" v-for="response in yesNo" :key="response" :value="response.id">{{response.value}}</option>
                                    </select>
                                    <div v-if="v$.system.publicFacing.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.publicFacing.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing leftspacing" v-if="system.publicFacing == '1'">
                                    <label class="controllabel">Add <span class="text-xs">(URL / IP Address / Domain Name)</span></label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="system.add"
                                        placeholder="Add"
                                        :class="{
                                            'is-invalid': v$.system.add.$error
                                        }"
                                    />
                                    <div v-if="v$.system.add.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.add.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing" :class="{'leftspacing':system.publicFacing != '1'}">
                                    <label class="controllabel">Hosting type</label>
                                    <select
                                        v-model="system.hostingType"
                                        @change="emptyProvider(system.hostingType)"
                                        :class="{
                                            'is-invalid': v$.system.hostingType.$error,
                                            'inputboxstylingForSelectAtPopup': system.hostingType === '',
                                            'stylingForInputboxstylingForSelectAtPopup': system.hostingType !== ''
                                        }"
                                    >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option class="optionColors" v-for="response in hostingTypeObj" :key="response" :value="response.value">{{response.value}}</option>
                                    </select>
                                    <div v-if="v$.system.hostingType.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingType.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-full upperspacing" v-if="this.system.hostingType == 'Cloud'" :class="{'leftspacing':system.publicFacing == '1'}">
                                    <label class="controllabel">Provider</label>
                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="system.hostingProvider" :placeholderValue="'Provider'" @name="getCategories" :error="v$.system.hostingProvider.$error"></dropdown>
                                    <div v-if="v$.system.hostingProvider.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingProvider.required.$invalid">Required</div>
                                    </div> -->
                                    <multiselect 
                                            v-model="system.hostingProvider" 
                                            :options="systemHosting"
                                            :disabled="system.hostingType == ''"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"   
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Provider"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.system.hostingProvider.$error,
                                                'has-options': systemHosting && systemHosting.length > 0,
                                                'no-options':  !systemHosting.length,
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No Types Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.system.hostingProvider.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingProvider.required.$invalid">Required</div>
                                    </div>
                                </div>
                                  <div class="col-md-6 w-full upperspacing" v-else :class="{'leftspacing':system.publicFacing == '1'}">
                                    <label class="controllabel">Provider</label>
                                    <!-- <dropdown :dataprops="selectedCategory" :inputValue="system.hostingProvider" :placeholderValue="'Provider'" @name="getCategories" :error="v$.system.hostingProvider.$error"></dropdown>
                                    <div v-if="v$.system.hostingProvider.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingProvider.required.$invalid">Required</div>
                                    </div> -->
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="system.hostingProvider"
                                        :disabled="system.hostingType == ''"
                                        placeholder="Provider"
                                        :class="{
                                            'is-invalid borderRed': v$.system.hostingProvider.$error,
                                        }"
                                    />
                                    <div v-if="v$.system.hostingProvider.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.system.hostingProvider.required.$invalid">Required</div>
                                    </div>
                                </div>
                               <div class="w-full upperspacing" :class="{'leftspacing':system.publicFacing != '1'}">
                                        <label class="controllabel">Hosting Country</label>
                                        <multiselect 
                                            v-model="system.country" 
                                            :options="countriesList"
                                            @select="changeCountry(system.country)"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            :hide-selected="true"
                                            open-direction="bottom"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Hosting Country"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.system.country.$error,
                                                'has-options': countriesList && countriesList.length > 0,
                                                'no-options':  !countriesList.length
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No Country Found</span>
                                        </template>
                                        </multiselect>

                                        <div v-if="v$.system.country.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.country.required.$invalid">Required</div>
                                            <div v-else-if="v$.system.country.required && v$.system.country.alphabet.$invalid" class="invalid-feedback text-xs">Country is Invalid</div>
                                        </div>
                               </div>
                                    <div class="w-full upperspacing" :class="{'leftspacing':system.publicFacing == '1'}">
                                        <label class="controllabel">Hosting City</label>
                                        <multiselect 
                                            v-model="system.city" 
                                            :options="citiesList"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            :hide-selected="true"
                                            :disabled="system.country == ''"   
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Hosting City"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.system.city.$error,
                                                'has-options': citiesList && citiesList.length > 0,
                                                'no-options' :  !citiesList.length && system.country
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No City Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.system.city.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.system.city.required.$invalid">Required</div>
                                            <div v-else-if="v$.system.city.required && v$.system.city.alphabet.$invalid" class="invalid-feedback text-xs">City is Invalid</div>
                                        </div>
                                    </div>
                                <!-- {{ system.city }}___ {{ citiesList }} -->
                        </div>
                    </div>
                    </div>
                </div>
                <div>
                    <!-- v-if="permissions[2]" -->
                <div v-if="editpop == false" class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                    <button class="btnprimary mr-1.5" :disabled="clickOnButton" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancelbtn(system._id)">Cancel</button>
                    <button class="btnprimary" :disabled="clickOnButton" v-if="!objToBeModified._id" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="addOrUpdate(system, 20005)">Save</button>
                </div>
                <div v-else-if="editpop== true" class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                    <button class="mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(objToBeModified) === JSON.stringify(system) ? 'flex btndisabled mr-2' : 'flex btnprimary mr-2'" @click="onCancelbtn('editpop')">Cancel</button>
                    <button :disabled="clickOnButton" :class="JSON.stringify(objToBeModified) === JSON.stringify(system) ? 'flex btndisabled pointer-events-none mr-2' : 'flex btnprimary mr-2'" @click="addOrUpdate(system, 20005)">Update</button>
                </div>
                </div>
            </div>
            </fieldset>
        </popup>
    </div>
    
</template>
<style scoped>
.borderRed{
    border: 1px solid red !important;
}
.inputboxstylingForSelectAtPopup .optionColors{
   color : #4D4D4D !important;
}
.ellipsis-container{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required,helpers,requiredIf } from '@vuelidate/validators';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { toast } from '@/main';
// const alphabet = helpers.regex(/^[a-zA-Z]+$/);
const alphabet = helpers.regex(/^\s*[a-zA-Z]+(?:\s+[a-zA-Z]+)*\s*$/);
import newTable from '@/components/newTable.vue';
import Multiselect from 'vue-multiselect';
import { emitter } from '@/main';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            dummydata: [],
            savedInformationSets:[],

            dummyList:[],
            filter: {},
            systemData: [],
            showFilters:false,
            systems: [],
            systemHosting : [],
            headers1:['systemName','Description','publicFacing','hostingType','_id'],

            systemValue: [
                {id:0,value: 'None'},
                {id: 1,value: 'Low'},
                {id: 2,value: 'Moderate'},
                {id: 3,value: 'High'} 
            ],
            systemsInformations: [],

            mandatoryKeys: ['system', '(Max of CIA)'],
            fullKeys: ['system', '(Max of CIA)'],
           

            popUp:false,
            selectedCategory:[],
            autoSuggestion:false,
            objToBeModified:{},
            users:[],
            tenants:[],
            tenantsSelect: [],
            clickOnButton: false,
            optionsUserArray:[],
            // permissions:[],

             system: {
                systemName: '',
                ownedBy: '',
                description: '',
                managedBy: '',
                owner:'',
                hostingType: '',
                hostingProvider: '',
                publicFacing: '',
                add: '',
                country: '',
                city: '',
                jobtitle : '',
                // informations:[]
            },
            countriesList: [],
            citiesList: [],
            stateInfoList: [],
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    CitiesName:['Hyderabad','Bangalore','Chennai']
                },
                {
                    CountryName: 'USA',
                    CitiesName:['California','Washington','New York']
                },
            ],
             cloud: ['AWS', 'Microsoft Azure', 'Google Cloud', 'DigitalOcean', 'IBM', 'Rackspace', 'Oracle', 'Red Hat', 'Alibaba'],
             hostingTypeObj:[
                {
                    id: 1,
                    value: 'On-premise'
                },
                {
                    id: 2,
                    value: 'Data center'
                },
                {
                    id: 3,
                    value: 'Cloud'
                },
            ],
            managedByOptions:[],
            businessEntitiesList:[],
            yesNo: [
                {
                    id: 1,
                    value: 'Yes'
                },
                {
                    id: 2,
                    value: 'No'
                },
               
            ],
            editpop : false,
            departmentList: [],
            thirdPartyDetails:[],
            userOptions : [],
            userOptionsManaged : [],
            bpOptions : [],
            systemNames : [],
            systemNamesExcludingPresentSystem : [],
            currentSystem : [],
            toolTipActive : false,
            toolTipText : '',
            comingId : '',
            dataLoading:true,
            searchValue: '',
        };
    },
    components: {
        popup,
        Multiselect,
        newTable,
        toolTip
    },
    validations(){
        return {
        system: {
                systemName: { required },
                ownedBy: { required },
                managedBy: { required },
                hostingType: { required },
                owner: { required },
                publicFacing: { required },
                description:{required},
                jobtitle:{required},
                add: {
                    required: requiredIf(() => {
                        return this.system.publicFacing == '1';
                    })
                },
                city: { required,alphabet },
                // state: { required,alphabet },
                country: { required,alphabet },
                hostingProvider: { required },
            }
        }
    },
    async mounted() {
        //this.$store.dispatch('updateDotsLoading', true);
         this.dataLoading = true;
        await this.getAllActiveUsers();
        await this.getAllTenants();
        // await this.getInformationSets()
        await this.getThirdParties();
        await this.getBusinessEntities();
        this.countriesStatesCities.map((obj: any) => {
            this.countriesList.push(obj.CountryName);
        });
        this.cloud.map((obj: any) => {
            this.systemHosting.push(obj);
        });
        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            console.log('comingId',isData)
            this.comingId = isData._id;
            let showObj = this.systems.find((obj: any) => {
                return obj._id == this.comingId;
            });
            if(this.systems != undefined){
                this.systemNamesExcludingPresentSystem = []
               this.systems.forEach((name: any)=>{
                if(this.comingId != name._id){
                    this.systemNamesExcludingPresentSystem.push(name.systemName)
                }
				})
            }
            this.currentSystem = showObj
            this.changeCountry(showObj.country);
            // this.changeState(showObj.state);
            this.showEditPopUp(showObj);
            // this.dummySystems = { ...showObj };
            // this.system = showObj;
            // this.changeCountry(this.system.country);
            // this.changeState(this.system.state);
            this.popUp = true;
        });
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
        await this.getSystems();
        await this.getSystemsInformation();
        this.dataLoading = false;
    },
  
    computed: {
        disableStatus: function (): any {
            let disableStatusCondition;
            if (this.objToBeModified._id) {
                if (_.isEqual(this.objToBeModified, this.dummySystems)) {
                    disableStatusCondition = false;
                } else {
                    disableStatusCondition = true;
                }
            } else {
                let val: any = [];
                Object.values(this.objToBeModified).forEach((value: any) => {
                    val.push(value);
                });
                for (let num of val) {
                    if (num != '') {
                        disableStatusCondition = true;
                    }
                }
            }
            return disableStatusCondition;
        },
        descriptionExceed:function():any{
            if(this.system.description.length > 1000){
                return true
            }else{
                return false
            }
        },
        publicFacingFalse(): any {
          if(this.system.publicFacing == 2){
             this.system.add = ''
          }
        },
        checkSystemName: function (): any {
             const trimmedSystemName = this.system.systemName.trim().toLowerCase();
             if(this.systemNames == null || this.systemNames == undefined || this.systemNames.length == 0){
                return false
             }
            if(this.editpop === false){
               let a = false;
               a = this.systemNames.map((name: any) => name.toLowerCase()).includes(trimmedSystemName);
                return a
            }else if(this.editpop == true){
                let a = false;
                 a = this.systemNamesExcludingPresentSystem.map((name: any) => name.toLowerCase()).includes(trimmedSystemName);
                return a
            }
        }
    },
    methods: {
        showEditPopUp(showObj: any) {
            this.popUp = true;
            this.editpop = true;
            this.system = { ...showObj };
            this.system.managedBy = this.managedByOptions.find((obj:any)=>{
                if(obj.value == this.system.managedBy){
                    return { value: obj.value , label:obj.label}
                }
            });
            this.system.ownedBy = this.departmentList.find((obj:any)=>{
                if(obj.value == this.system.ownedBy){
                    return { value: obj.value , label:obj.label}
                }
            });
            this.system.owner = this.optionsUserArray.find((obj:any)=>{
                if(obj.value == this.system.owner){
                    return { value: obj.value , label:obj.label}
                }
            });
            if(showObj.hostingType == '-' || showObj.hostingType == null || showObj.hostingType == undefined || showObj.hostingType == '' || showObj.hostingType == 1 || showObj.hostingType == 2 || showObj.hostingType == 3){
                this.system.hostingType = ''
            }
            this.objToBeModified = {...this.system};
            this.dummySystems = { ...this.system };
        },
        // countryChanged() {
        //     this.system.city = '';
        // },
        // changeCountry(Country: any) {
        //     this.statesList = [];
        //     this.stateInfoList = [];
        //     let findobj = this.countriesStatesCities.find((obj: any) => {
        //         return obj.CountryName == Country;
        //     });
        //     findobj.States.map((obj: any) => {
        //         this.stateInfoList.push(obj);
        //         this.statesList.push(obj.StateName);
        //     });
        //     this.system.state = '';
        //     this.system.city = '';
        // },
        changeCountry(Country: any) {
        console.log("Country",Country)
        this.citiesList = [];
        let findobj = this.countriesStatesCities.find((obj: any) => {
            return obj.CountryName == Country;
        });
        this.citiesList = findobj.CitiesName;
        this.system.city = '';
        },
        selectedSystemOwner() {
        const ownerValue = this.system?.owner?.value;
        if (ownerValue) {
        const selectedUser = this.users.find((obj:any) => obj._id === ownerValue);
        this.system.jobtitle = selectedUser?.jobTitle; 
        } else {
        this.system.jobtitle = 'N/A'; 
        }
        },


        emptyProvider(obj:any){
            this.system.hostingProvider = ''
            
        },
        showPopUp() {
            this.v$.$reset();
            Object.keys(this.system).forEach((prop) => {
               Array.isArray(this.system[prop])? this.system[prop]=[]:this.system[prop] = '';
            });
            this.objToBeModified = this.system;
            this.popUp = true;
            this.editpop = false;
        },
        getOrgName(companyId:any){
            if(this.popUp == true){
                let details = this.tenants.find((obj:any)=>{return obj.id == companyId})
                if(details == undefined){
                    return companyId;
                }else{
                    return details.name;
                }
            }        
        },
        // checkSystemName(systemName: any) {
        //     const trimmedSystemName = systemName.trim().toLowerCase();
        //     let array: any;

        //     if (this.editpop === true) {
        //         let originalObj = this.systems.find((obj: any) => obj._id === this.currentSystem._id);
        //         array = this.systemNames.filter((obj: any) => obj.toLowerCase() !== originalObj.systemName.toLowerCase());
        //         const trimmedCheckedTitle = trimmedSystemName;
        //         let check = array.includes(trimmedCheckedTitle);
        //         return check;
        //     }

        //     if (this.editpop === false) {
        //         const check = this.systemNames.map((name: any) => name.toLowerCase()).includes(trimmedSystemName);
        //         return check;
        //     }
        // },
        asyncFind(query:any){
            this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 1){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        asyncFindManagedBy(query:any){
            this.searchValue = query;
			if(query == ''){
				this.userOptionsManaged = []
			}else if(query.length > 1){
				this.userOptionsManaged = this.managedByOptions.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        asyncFindBP(query:any){
            this.searchValue = query;
			if(query == ''){
				this.bpOptions = []
			}else if(query.length > 1){
				this.bpOptions = this.businessEntitiesList.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        onCancelbtn(status: any) {
            if (status === 'close') {
                this.popUp = false;
                this.v$.$reset();           
            }
            //  else if (status) {
            //     this.system = {...this.dummySystems}
            //     this.objToBeModified = this.system;
            // }
            else if (status === 'editpop') {
                this.changeCountry(this.objToBeModified.country);
                // this.changeState(this.objToBeModified.state);
                this.system = {...this.objToBeModified}
                this.v$.$reset(); 
                // this.system.state = this.objToBeModified.state;
                // this.system.city = this.objToBeModified.city;
                // this.changeCountry(this.system.country);
                // this.changeState(this.system.state);
                // this.objToBeModified = this.system;
            }
             else if (!undefined) this.showPopUp();
        },
        // getCategories(options: any, status: any) {
        //     status = status.charAt(0).toLowerCase() + status.replace(/\s+/g, '').substring(1);
        //     this.filteredCategories(options, status);
        // },
        // filteredCategoriesAddress(options: any, status: any){
        //     if (status == 'country') {
        //         this.selectedCategory = this.countriesStatesCities
        //             .map((country: any) => {
        //                 return country.CountryName;
        //             })
        //             .filter((category: any) => {
        //                 return category.toLowerCase().includes(options.toLowerCase());
        //             });
        //         this.objToBeModified[status] = options;
        //     }
        //     if (status == 'state') {
        //         this.selectedCategory = this.countriesStatesCities
        //             .filter((item: any) => item.CountryName == this.objToBeModified.country)
        //             .map((state: any) =>
        //                 state.States.map((statename: any) => {
        //                     return statename.StateName;
        //                 })
        //             )
        //             .flat(1)
        //             .filter((category: any) => {
        //                 return category.toLowerCase().includes(options.toLowerCase());
        //             });
        //         this.objToBeModified[status] = options;
        //     }
        //     if (status == 'city') {
        //         this.selectedCategory = this.countriesStatesCities
        //             .filter((item: any) => item.CountryName == this.objToBeModified.country)
        //             .map((state: any) =>
        //                 state.States.map((statename: any) => {
        //                     if (statename.StateName == this.objToBeModified.state) {
        //                         return statename.Cities;
        //                     } else {
        //                         return [];
        //                     }
        //                 })
        //             )
        //             .flat(2)
        //             .filter((category: any) => {
        //                 return category.toLowerCase().includes(options.toLowerCase());
        //             });
        //         this.objToBeModified[status] = options;
        //     }
        // },
        // filteredCategories(options: any, status: any) {
        //     this.selectedCategory = [];
        //     this.autoSuggestion = true;
        //     if(status == 'country'|| status == 'state' || status == 'city'){
        //        this.filteredCategoriesAddress(options, status)
        //     }
        //     else{
        //         switch(status){
        //             case 'managedBy':  
        //                 console.log("optionsoptions",options)
        //                 console.log("statusstatus",status)
        //                 break;
        //             case 'provider':
        //                 if (this.system.hostingType == 'data-center' || this.system.hostingType == 'cloud') {
        //                 this.selectedCategory = this.cloud.filter((dept: any) => {
        //                     return dept.toLowerCase().includes(options.toLowerCase());
        //                 });
        //             }
        //                 this.objToBeModified.hostingProvider = options;   
        //                 break;  
        //         }
        //     }      
        // },
        async systemInformation(systemId: any) {
            this.$router.push({ name: 'edit-system', params: { id: systemId } });
        },
        // async getInformationSets() {
        //     this.savedInformationSets = [];
        //     await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/root/information/getall`).then((result: any) => {
        //         this.savedInformationSets = result.data.map((info: any) => {
        //             return { value: info._id, label: info.information };
        //         });
        //     });
        // },
        async addOrUpdate(item: any, entityId: number) {
            this.v$.system.$touch();
            let obj:any = {...item}
            let comingObjId = obj._id;
            if (!this.v$.system.$invalid && this.checkSystemName == false && this.descriptionExceed == false) {
                this.clickOnButton = true;
                if (comingObjId) {
                    try{
                        delete obj._id;
                        obj.updatedAt=new Date();
                        obj.ownedBy = obj.ownedBy.value
                        obj.managedBy = obj.managedBy.value
                        obj.owner = obj.owner.value
                        await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/update/${comingObjId}/${entityId}`, obj).then((result: any) => {
                            toast.info('updated', {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                            this.popUp = false;
                        });
                    } catch{
                        toast.error(`cannot updated`, {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } finally {
                        this.clickOnButton = false;
                    }
                } else{
                    try{
                        // this.v$.$reset();
                        obj.createdAt=new Date();
                        obj.ownedBy = obj.ownedBy.value
                        obj.managedBy = obj.managedBy.value
                        obj.owner = obj.owner.value
                        delete obj._id;
                        await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/create/${entityId}`, obj).then((result: any) => {
                        toast.info('created', {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                            this.popUp = false;
                        });
                    } catch{
                        toast.error(`cannot Create`, {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    } finally {
                        this.clickOnButton = false;
                    }
                }
                
                this.systemData=[];
                this.getSystems();
                this.getSystemsInformation()
                this.v$.$reset();
            }
            //  else {
            //     toast.error(`cannot save`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
           
        },
        async getSystems() {
             let entityId='20005'
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                this.systems = result.data;
				this.dummySystems = result.data
                 this.systemNames = []
               this.systems.forEach((name: any)=>{
                if(this.name != '' || this.name != undefined || this.name != null ){
                  	this.systemNames.push(name.systemName)
                } 
				})
                
            });
       
        },
        async getSystemsInformation() {
            this.systemData = []
            this.dataLoading = true;
            await this.$http.get(`${process.env.VUE_APP_RISK_API_URL}/api/org/allsystems/cia`).then((result: any) => {
                this.systemsInformations = result.data;
                this.dummyList=result.data;
                for (let j = 0; j < this.systems.length; j++) {
                    let findobj = this.systemsInformations.find((obj:any)=>{return obj.id == this.systems[j]._id});
                    if(this.systems[j].hostingType == 1 || this.systems[j].hostingType == 2 || this.systems[j].hostingType == 3 || this.systems[j].hostingType == null || this.systems[j].hostingType == undefined){
                        this.systems[j].hostingType = '-'
                    }
                    // console.log("this.systems[j]._id != undefined|| this.systems[j]._id != '' || this.systems[j]._id != null ",this.systems[j]._id);
                    
                    if(this.systems[j]._id != ''&& this.systems[j]._id != undefined && this.systems[j]._id != null ){
                        // console.log("this.systems[j]._id != ''",this.systems[j]._id != '');
                        
                        this.systemData.push({ 'entityId':20009,'systemName': this.systems[j].systemName, 'Description': this.systems[j].description, 'publicFacing':this.systems[j].publicFacing, 'hostingType':this.systems[j].hostingType , _id: this.systems[j]._id });
                        this.dataLoading = false;
                        // console.log("this.systemDatas",this.systemData);
                    }
                    
                }
                // this.$store.dispatch('updateDotsLoading', false);
                for(let item of this.systemData){
                    if(item.cia == null){
                        item.cia = 0
                    }
                }
            });
           
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id:org._id, name: org.organizationName }
                    this.tenants.push(obj);
                    const object = { value:org._id, label: org.organizationName }
                    this.tenantsSelect.push(object);
                });
            });
        },
         async getAllActiveUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
            
        },
        async getBusinessEntities(){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result:any)=>{
                result.data.map((obj:any)=>{
                    // let name = this.tenantsSelect.find((tenant:any)=>{return obj.entityName == tenant.value});
                    // let final;
                    // if(name == undefined){
                    //     final = obj.entityName
                    // }else{
                    //     final = name.label
                    // }
                    for (var i = 0; i < result.data.length; i++) {
                        let a = {value:result.data[i]._id, label:result.data[i].entityName}
                        this.departmentList.push(a);
                    }
                    let values = {label : obj.entityName , value : obj._id }
                    if((!this.managedByOptions.includes(values))){
                        this.managedByOptions.push(values)
                        this.businessEntitiesList.push(values)
                    }
                })
            })
        },
        filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.systemData;
            } else {
                this.systemData = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        // getPermisssions(){
        //            this.permissions=this.userInfo.permissions[0].resources.filter((userPer:any)=>{
        //     return userPer.id==this.selectedIndex
        // })
        //  this.permissions= this.permissions[0].permissions
        //   console.log("kjeshdekjdssfe",this.permissions)
        // },
        async getThirdParties(){
            let thirdPartyId = 20003;
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${thirdPartyId}`).then((result: any) => {
                result.data.map((obj:any)=>{
                    const BPobj = { value: obj._id, label: obj.name };
                    this.thirdPartyDetails.push(BPobj)
                    if((!this.managedByOptions.includes(BPobj))){
                        this.managedByOptions.push(BPobj)
                    }
                });

            })
        }
    }
});
</script>



