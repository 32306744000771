<template>
    <div class="overflow-x-hidden overflow-y-hidden px-5 pt-3">
        <div class="flex justify-between items-center">
            <h1 class="title">Regions</h1>
            <div class="flex items-center">
                <button class="btnprimary mr-2" @click="addRegionPopup(regionObj, 'add')">Add</button>
                <button @click="filterOpen" class="flex" :class="regionDataArray.length == 0?'btndisabled pointer-events-none':'filterButton'">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 fontSizeInPage">
                The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
        </div>
        <div class="mt-3">
            <newTable :tableRowData="regionDataArray" :headersData="headers" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>
            <div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
        </div>
        <popup v-if="showRegionPopUp" :popUp="showRegionPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between py-4 px-5 rounded">
                    <h1>Region</h1>
                    <button
                        class="float-right text-3xl"
                        @click="onCancelButton('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer pt-3 px-5">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full">
                                        <div class="w-full">
                                            <label class="controllabel font-semibold">Region Name</label>
                                            <input
                                                type="text"
                                                class="inputboxstyling labelMarginTop fontSizeInpopup"
                                                v-model.trim="regionObj.region"
                                                placeholder="Region Name"
                                                :class="{
                                                    'is-invalid': (v$.regionObj.region.$error || checkRegionName)
                                                }"
                                            />
                                            <div v-if="v$.regionObj.region.$error || checkRegionName" class="text-red-500">
                                                <div class="error-text" v-if="v$.regionObj.region.required.$invalid">Required</div>
                                                <div v-else-if="checkRegionName" class="error-text text-xs">Region Name already exists.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full mt-3">
                                        <div class="w-6/12 ">
                                            <label class="controllabel font-semibold">Head Quarters (Optional)</label>
                                            <!-- <input
                                                type="text"
                                                class="inputboxstyling labelMarginTop fontSizeInpopup"
                                                v-model="regionObj.regionHeadQuaters"
                                                placeholder="Head Quarters"  
                                            /> -->
                                             <!-- :class="{
                                                    'is-invalid': v$.regionObj.regionHeadQuaters.$error
                                                }" -->
                                            <!-- <div v-if="v$.regionObj.regionHeadQuaters.$error" class="text-red-500">
                                                <div class="error-text pl-2" v-if="v$.regionObj.regionHeadQuaters.required.$invalid">Required</div>
                                            </div> --> 
                                            <!-- {{locationOptions}} -->
                                             <multiselect
                                                v-model="regionObj.regionHeadQuaters"
                                                :options="locationOptions"
                                                @search-change="asyncFindForLocation"
                                                :showNoOptions="false"
                                                open-direction="bottom"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :hide-selected="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Head Quarters"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'has-options': locationOptions && locationOptions.length > 0,
                                                    'no-options': !locationOptions.length &&  searchValue !== '',

                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No Entites Found</span>
                                            </template>
                                          </multiselect>
                                        </div>
                                        <div class="w-6/12 ml-4">
                                            <label class="controllabel font-semibold">Region Owner</label>
                                            <!-- <Multiselect
                                                v-model="regionObj.regionOwner"
                                                :options="optionsUserArray"
                                                :searchable="true"
                                                :minChars="1"
                                                :filterResults="true"
                                                class="multiselect-custom labelMarginTop"
                                                :classes="{singleLabelText:'text-xs overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',}"
                                                :canClear='false'
                                                :caret='false'
                                                placeholder="Owner"
                                                :class="{
                                                    'borderRed': v$.regionObj.regionOwner.$error,
                                                }" 
                                            ></Multiselect> -->
                                            <multiselect 
                                            v-model="regionObj.regionOwner" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Region Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.regionObj.regionOwner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Members Found</span>
                                        </template>
                                        </multiselect>
                                                <div v-if="v$.regionObj.regionOwner.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.regionObj.regionOwner.required.$invalid">Required</div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div v-if="editingRegionPopup == false" class="buttonposition flex justify-center items-center my-4 mx-0">
                        <button class="mr-1.5" @click="onCancelButton('cancel')" :disabled="clickOnButton" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled pointer-events-none':'btnprimary'">Cancel</button>
                        <button v-if="!regionObj._id" :disabled="clickOnButton" @click="AddRegionData" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled pointer-events-none':'btnprimary'">Add</button>
                    </div>
                    <div v-if="editingRegionPopup == true" class="buttonposition flex justify-center items-center my-4 mx-0">
                        <button class="mr-1.5" :disabled="clickOnButton" @click="onCancelButton('cancel')" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled pointer-events-none':'btnprimary'">Cancel</button>
                        <button @click="updateRegionData" :disabled="clickOnButton" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled pointer-events-none':'btnprimary'">Update</button>
                    </div>
                </div>

            </fieldset>
        </popup>

    </div>
         
</template>
<style scoped>
.controllabel{
    font-size: 12px;
}
.labelMarginTop{
    margin-top: 8px;
}
.empty tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue';
import popup from '@/components/popup.vue'
import { emitter, toast } from '@/main';
// import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue'


export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            headers:['region','entityCount','regionHeadQuaters'],
            showRegionPopUp:false,
            dummyRegionObject:{},
            regionObj:{
                region:'',
                regionHeadQuaters:'',
                regionOwner:'',
            },
            regionDataArray:[],
            showFilters:false,
            users:[],
            optionsUserArray:[],
            entityDataList : [],
            entitesDataArray:[],
            editingRegionPopup:false,
            editpop: false,
            userOptions : [],
            existingAuditsArray : [],
            regionNames : [],
            addVariable : false,
            selectedRegionId : '',
            locationOptions : [],
            filteredEntities : [],
            toolTipActive:false,
            toolTipText:'',
            clickOnButton: false,
            regionNamesExcludingPresentSystem : [],
            dataLoading:true,
            searchValue:'',
        };
    },
    components: {
        newTable,
        popup,
        Multiselect,
        toolTip
    },
    validations(){
        return{
            regionObj:{
                region: {required},
                // regionHeadQuaters: {required},
                regionOwner: {required},
            }
        }
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo' }),
         checkRegionName: function (): any {
            const trimmedRegionName = this.regionObj.region.trim().toLowerCase();
            if(this.regionDataArray == null || this.regionDataArray == undefined || this.regionDataArray.length == 0){
                return false
            }
            if(this.addVariable == true && this.regionDataArray.length > 0){
               let a = false;
               a = this.regionNames.map((name: any) => name.toLowerCase()).includes(trimmedRegionName);
                return a
            }
        }
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        addRegionPopup(){
            // this.regionObj = [];
            this.filteredEntities = []
            this.addVariable = true;
            this.regionObj = {region:'',regionHeadQuaters:'',regionOwner:'',_id:''}
            this.dummyRegionObject = {...this.regionObj};
            this.showRegionPopUp = true;
            this.editingRegionPopup = false;
        },
        onCancelButton(state:any){
            if(state == 'close'){
                this.addVariable == false
                this.regionObj = [];
                this.showRegionPopUp = false;  
                this.editingRegionPopup = false; 
                this.dummyRegionObject = {region:'',regionHeadQuaters:'',regionOwner:''}        
            }else{
                if(this.editingRegionPopup == true){
                    this.regionObj = {...this.dummyRegionObject}
                }else{
                    this.regionObj = {region:'',regionHeadQuaters:'',regionOwner:'',_id:''}
                }
            }
            this.v$.$reset();
        },
        editRegion(comingObj:any){
            this.showRegionPopUp = true;
            this.editingRegionPopup = true;
            this.regionObj = {...this.dummyRegionObject}
            // this.dummyRegionObject = {region:'',regionHeadQuaters:'',regionOwner:'',_id:''}
            let keys:any = ['_id','region','regionHeadQuaters','regionOwner']
            for(const i in keys){
                this.regionObj[keys[i]] = comingObj[keys[i]];
                this.dummyRegionObject[keys[i]] = comingObj[keys[i]];
            }
            console.log('editRegion',this.regionObj);
            
        },
        asyncFind(query:any){
            this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 1){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        asyncFindForLocation(query:any){
            this.searchValue = query;
            this.filteredEntities = []
            this.entitesDataArray.map((obj:any)=>{
                if(obj.region == this.regionObj._id){
                    console.log('NEW OBJ',obj);   
                    this.filteredEntities.push({value : obj._id , label : obj.entityName})
                }
            })
			if(query == ''){
				this.locationOptions = []
			}else if(query.length > 1){
				this.locationOptions = this.filteredEntities.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},

//        checkRegionName(regionName: any) {
//     if (this.regionDataArray.length > 0 && regionName !== '' && regionName !== null) {
//         const trimmedRegionName = regionName.trim().toLowerCase();
//         let array: any = [];

//         // Check for existing regions when adding a new one
//         if (this.addVariable === false && this.regionDataArray.length > 0) {
//             let originalObj = this.existingAuditsArray.find((obj: any) => {
//                 return obj._id === this.selectedRegionId;
//             });
//             // Filter out the original region from the list if it exists
//             if (originalObj && originalObj.region != null) {
//                 array = this.regionNames.filter((obj: any) => {
//                     return obj.toLowerCase() !== originalObj.region.toLowerCase();
//                 });
//             } else {
//                 array = this.regionNames; // If no original region, use all region names
//             }

//             const trimmedCheckedTitle = trimmedRegionName;
//             let check = array.map((name: any) => name.toLowerCase()).includes(trimmedCheckedTitle);
//             return check;
//         }

//         // Check for existing regions when adding a new one
//         if (this.addVariable === true && this.regionDataArray.length > 0) {
//             array = this.regionNames; // Fill the array with all region names
//             const check = array.map((name: any) => name.toLowerCase()).includes(trimmedRegionName);
//             return check;
//         }
//     }
// },

        async AddRegionData(){
            this.v$.regionObj.$touch();
            if (!this.v$.regionObj.$invalid && this.checkRegionName == false) {
                let copyOfRegionObject :any = {...this.regionObj};
                this.clickOnButton = true;
                copyOfRegionObject.regionOwner = copyOfRegionObject.regionOwner.value    
                if(copyOfRegionObject.regionHeadQuaters != undefined){
                    copyOfRegionObject.regionHeadQuaters = copyOfRegionObject.regionHeadQuaters.value 
                } 
                console.log('CONSOLE',copyOfRegionObject) ;
                delete copyOfRegionObject._id;
                let payload = {};
                payload = {...copyOfRegionObject};
                console.log('ppppppppp',payload)
                try{
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/region/create`, payload).then(async (res: any) => {
                        console.log('sucesssss')
                        toast.info('saved', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        this.onCancelButton('close');
                        await this.getAllRegions();
                    })
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }

                
            }
            // else{
            //     toast.error(`cannot save`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        async updateRegionData(){
            this.v$.regionObj.$touch();
            if (!this.v$.regionObj.$invalid && this.checkRegionName == false) { 
                this.clickOnButton = true;
                let copyOfRegionObject :any = {...this.regionObj};
                let regionId = copyOfRegionObject._id;
                delete copyOfRegionObject._id   
                copyOfRegionObject.regionOwner = copyOfRegionObject.regionOwner.value     
                console.log('CONSOLE',copyOfRegionObject) ;
                 if(copyOfRegionObject.regionHeadQuaters != undefined){
                    copyOfRegionObject.regionHeadQuaters = copyOfRegionObject.regionHeadQuaters.value 
                }
                console.log('$$',copyOfRegionObject) ; 
                let payload = {};
                payload = {...copyOfRegionObject};      
                try{
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/region/${regionId}/update`, payload).then(async (res: any) => {
                        console.log('sucesssss')
                        toast.info('Updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        this.onCancelButton('close');
                        await this.getAllRegions();
                    })
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }
                
            }
            // else{
            //     toast.error(`Cannot Update`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        async getAllRegions(){
            this.regionDataArray = [];
            this.dataLoading = true;
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
                console.log('sucesssss',res.data)
                this.existingAuditsArray = res.data.filter((obj:any)=>{return (obj.region != undefined && obj.region != null && obj.region != '') })
                this.existingAuditsArray.map((obj:any)=>{
                    console.log('objjjjj',obj)
                    var RegionOwner = this.optionsUserArray.find((entityObj: any) => {
                                 return entityObj.value == obj.regionOwner
                            });
                            obj.regionOwner = RegionOwner;
                    let userDetails:any;
                    Object.keys(obj).includes('regionOwner') ? userDetails = this.users.find((user:any)=>{return obj.regionOwner == user._id}) : userDetails == undefined;
                    userDetails!=undefined ? obj.email = userDetails.email : obj.email = '';
                    userDetails!=undefined ? obj.ownerName = userDetails.name : obj.ownerName = '';

                    obj.entities = [];
                    let entityFilter = this.entitesDataArray.filter((entity:any)=>{return obj._id == entity.region});
                    
                    if(entityFilter.length > 0){
                        obj.entityCount = entityFilter.length;
                        entityFilter.map((entity:any)=>{obj.entities.push(entity._id)})
                    }else{
                        obj.entityCount = 0;
                    }
                    if(obj.regionHeadQuaters != undefined){  
                       obj.regionHeadQuaters = this.entityDataList.find((entity:any)=>{
                        return entity.value == obj.regionHeadQuaters
                    })
                    console.log('))))))))))))))',obj.regionHeadQuaters);
                    }
                    
                    let newObj = {
                        _id :obj._id,
                        'region' : obj.region != undefined ? obj.region : 'N/A',
                        'entityCount' : obj.entityCount!= undefined ? obj.entityCount : 'N/A',
                        'regionHeadQuaters' : obj.regionHeadQuaters != undefined ? obj.regionHeadQuaters.label : 'N/A'
                    };

                    this.regionDataArray.push(newObj);
                    this.dataLoading = false;
                })
                 this.regionNames = []
                this.existingAuditsArray.forEach((name: any)=>{
                    if(name.region != null){
                        
                       this.regionNames.push(name.region)
                    }
				})
                console.log('ALL NAMES',this.regionNames)
            })
        },
        async getAllEntities(){
			this.entitesDataArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				this.entitesDataArray = res.data;
            })
             for (var i = 0; i < this.entitesDataArray.length; i++) {
                this.entityDataList.push({ value: this.entitesDataArray[i]._id , label: this.entitesDataArray[i].entityName});
            }
            console.log('entityyy',this.entitesDataArray);
            
		},
         async getAllActiveUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
            
        },

    },
    async created(){
        this.dataLoading = true;
        await this.getAllActiveUsers();
        await this.getAllEntities();
        await this.getAllRegions();
        this.dataLoading = false;
    },  
    async mounted(){
        emitter.off('dataTransfering')
        emitter.on('dataTransfering', (Data: any) => {
            // let obj
            // obj = this.existingAuditsArray.find((newObj:any)=>{
            //     return newObj._id == Data._id
            // })
            // console.log('selected Array',obj);

    
            // this.editRegion(obj);
            // this.selectedRegionId = Data._id
            //  this.addVariable = false;

            this.$router.push({name:'businessEntitesForRegion',params:{regionId:Data._id},query:{region:true}})
        });

        // emitter.off('goToInnerPage')
        // emitter.on('goToInnerPage', (Data: any) => {
        //     this.$router.push({name:'businessEntitesForRegion',params:{regionId:Data._id},query:{region:true}})
        // })

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
});
</script>

