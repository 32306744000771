<template>
    <div class="overflow-x-hidden px-5 pt-3">
        <div class="mb-3">
            <breadCrumbs :routesArray="routesArray"></breadCrumbs>
        </div>
        <h1 class="title">Business Entity</h1>
        <div class="mt-3 fontSizeInPage">
            Data organization is the practice of categorizing and classifying data to make it more usable. Similar to a file folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion, so you and anyone else who accesses it can easily find
            what they're looking for Data organization is the practice of categorizing and classifying data to make it more usable, Similar to a file folder, where we keep important documents, you'll need to arrange your data in the most logical and orderly fashion.
        </div>
        <div class="border border-lightgrey bg-white mt-3 w-full rounded">
            <!--  style="max-height: calc(100vh - 250px);" -->
            <div class="overflow-auto pt-3 px-5 mb-10">
                <div class="formcontainer flex flex-col">
                    <div class="row flex flex-row w-full">
                        <div class="w-6/12">
                            <label class="controllabel font-semibold">Entity Name</label>
                            <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" placeholder="Entity Name" type="text" v-model.trim="businessProcess.entityName" :class="{'is-invalid': v$.businessProcess.entityName.$error || checkEntityName(businessProcess.entityName)}" />
                            <div v-if="v$.businessProcess.entityName.$error">
                                <div v-if="v$.businessProcess.entityName.required.$invalid" class="text-red-500 text-xs">Required</div>
                            </div>
                             <div v-if="checkEntityName(businessProcess.entityName)" class="text-red-500">
                                <div class="error-text">Entity Name already exists.</div>
                            </div>
                        </div>
                        <div class="w-6/12 pl-4">
                            <label class="controllabel font-semibold">Display Name</label>
                            <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" placeholder="Display Name" type="text" v-model.trim="businessProcess.entityDisplayName" :class="{ 'is-invalid': v$.businessProcess.entityDisplayName.$error || checkDisplayEntityName(businessProcess.entityDisplayName)}" />
                            <div v-if="v$.businessProcess.entityDisplayName.$error">
                                <div v-if="v$.businessProcess.entityDisplayName.required.$invalid" class="text-red-500  text-xs">Required</div>
                            </div>
                             <div v-if="checkDisplayEntityName(businessProcess.entityDisplayName)" class="text-red-500">
                                <div class="error-text">Entity Display Name already exists.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="formcontainer flex flex-col">
                    <div class="row flex flex-row w-full mt-3">
                        <div class="w-6/12">
                            <label class="controllabel font-semibold">Region</label>
                            <div v-if="(presentRegionName !=  undefined && Object.keys(this.$route.params).includes('regionId') || (Object.keys(this.$route.params).includes('regionId') && Object.keys(this.$route.params).includes('entityId')))">
                                    <multiselect
                                    v-model="businessProcess.region"
                                     :options="regionOptions"
                                     :multiple="false"
                                     label="label"
                                     track-by="value"    
                                     :hideArrow="false"
                                     disabled
                                     placeholder="Region"
                                     class="custom-multiselect userSelect"
                                     :class="{
                                        'is-invalid borderRed':  v$.businessProcess.region.$error,
                                    }"
                                >
                                    <template v-slot:noResult>
										<span class="text-xs">No Regions Found</span>
									</template>
                                </multiselect>
                                </div>
                                     <div v-if="(presentRegionName ==  undefined || this.$route.path.includes('/addEntity') && (!Object.keys(this.$route.query).includes('addAtRegions'))) ||  (this.$route.path.includes('/editEntity') && (!Object.keys(this.$route.params).includes('regionId')))">
                                     <multiselect
                                            v-model="businessProcess.region"
                                            :options="regionList"
                                            :filter="filterOptions"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Region"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.businessProcess.region.$error,
                                                'has-options': regionOptions && regionOptions.length > 0,
                                                'no-options': !regionOptions.length
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Regions Found</span>
                                        </template>
                                        </multiselect></div>
                                        <div v-if="v$.businessProcess.region.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.businessProcess.region.required.$invalid">Required</div>
                                        </div>
                        </div>
                        <div class="w-6/12 pl-4">
                            <label class="controllabel font-semibold">Entity Type</label>
                            <select class="w-full labelMarginTop fontSizeInpopup" placeholder="Entity Type" v-model="businessProcess.entityType"  :class="{ 'is-invalid': v$.businessProcess.entityType.$error,'inputboxstylingForSelectAtPopup': businessProcess.entityType === '','stylingForInputboxstylingForSelectAtPopup': businessProcess.entityType !== '' }">
                                <option hidden value="">Select</option>
                                <option v-for="obj in entityTypeObject" :key="obj" :value="obj.id" :disabled="disablePrimaryEntity && obj.id === 1" :class="disablePrimaryEntity && obj.id === 1 ? 'taskStatusOptionsForNotStarted' : 'taskStatusOptions'">
                                    {{obj.value}}
                                </option>
                            </select>
                            <div v-if="v$.businessProcess.entityType.$error" class="text-red-500">
                                <div class="error-text" v-if="v$.businessProcess.entityType.required.$invalid">Required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="formcontainer flex flex-col">
                    <div class="row flex flex-row w-full mt-3">
                        <div class="w-6/12">
                            <label class="controllabel font-semibold">Owner</label>
                                    <multiselect
                                            v-model="businessProcess.owner"
                                            :options="userOptions"
                                            @search-change="asyncFind"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.businessProcess.owner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length && searchValue !== '',
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Members Found</span>
                                        </template>
                                        </multiselect>
                            <div v-if="v$.businessProcess.owner.$error" class="text-red-500">
                                <div class="error-text" v-if="v$.businessProcess.owner.required.$invalid">Required</div>
                            </div>
                        </div>
                        <div class="w-6/12 pl-4">
                            <label class="controllabel font-semibold">Industry Sector</label>
                            <input class="inputboxstyling w-full labelMarginTop fontSizeInpopup" placeholder="Industry Sector" type="text" v-model.trim="businessProcess.industrySectorName"
                           
                             />
 
                            <!-- <div v-if="v$.businessProcess.industrySectorName.$error">
                                <div v-if="v$.businessProcess.industrySectorName.required.$invalid" class="text-red-500 text-xs">Required</div>
                            </div>
                            :class="{ 'is-invalid': v$.businessProcess.industrySectorName.$error }" -->
                        </div>
                    </div>
                </div>
                <div class="w-full mt-3">
                    <label class="controllabel font-semibold">Address Line 1</label>
                    <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" type="text" placeholder="Address Line 1" v-model.trim="businessProcess.addressLineOne" :class="{ 'is-invalid': v$.businessProcess.addressLineOne.$error}"/>
 
                    <div v-if="v$.businessProcess.addressLineOne.$error">
                        <div v-if="v$.businessProcess.addressLineOne.required.$invalid" class="text-red-500 text-xs">Required</div>
                    </div>
                </div>
                <div class="w-full mt-3">
                    <label class="controllabel font-semibold">Address Line 2</label>
                    <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" type="text" placeholder="Address Line 2" v-model.trim="businessProcess.addressLineTwo"  />
 
                    <!-- :class="{ 'is-invalid': v$.businessProcess.addressLineTwo.$error}"
                    <div v-if="v$.businessProcess.addressLineTwo.$error">
                        <div v-if="v$.businessProcess.addressLineTwo.required.$invalid" class="text-red-500">Required</div>
                    </div> -->
                </div>
                <div class="formcontainer flex flex-col">
                    <div class="row flex flex-row w-full mt-3">
                        <div class="w-6/12">
                            <label class="controllabel font-semibold">City</label>
                            <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" placeholder="City" type="text" v-model.trim="businessProcess.city" :class="{ 'is-invalid': v$.businessProcess.city.$error }" />
                            <div v-if="v$.businessProcess.city.$error">
                                <div v-if="v$.businessProcess.city.required.$invalid" class="text-red-500  text-xs">Required</div>
                                <div v-else-if="v$.businessProcess.city.required && v$.businessProcess.city.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                            </div>
                        </div>
                        <div class="w-6/12 pl-4">
                            <label class="controllabel font-semibold">State</label>
                            <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" type="text" placeholder="State" v-model.trim="businessProcess.state" :class="{ 'is-invalid': v$.businessProcess.state.$error}" />
                            <div v-if="v$.businessProcess.state.$error">
                                <div v-if="v$.businessProcess.state.required.$invalid" class="text-red-500  text-xs">Required</div>
                                <div v-else-if="v$.businessProcess.state.required && v$.businessProcess.state.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="formcontainer flex flex-col">
                    <div class="row flex flex-row w-full mt-3 mb-5">
                        <div class="w-6/12">
                            <p class="text-xs font-semibold">Country</p>
                            <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop" placeholder="Country" type="text" v-model.trim="businessProcess.country" :class="{ 'is-invalid': v$.businessProcess.country.$error}" />
                            <div v-if="v$.businessProcess.country.$error">
                                <div v-if="v$.businessProcess.country.required.$invalid" class="text-red-500 text-xs">Required</div>
                                <div v-else-if="v$.businessProcess.country.required && v$.businessProcess.country.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                            </div>
                        </div>
                        <div class="w-6/12 pl-4">
                            <p class="text-xs font-semibold">Pin Code</p>
                            <input class="inputboxstyling w-full fontSizeInpopup labelMarginTop"
                                placeholder="Pin Code"
                                type="text"
                                v-model.trim="businessProcess.zipCode"
                                :class="{ 'is-invalid': v$.businessProcess.zipCode.$error }"
                                @input="v$.businessProcess.zipCode.$touch()" 
                            />
                            <div v-if="v$.businessProcess.zipCode.$error" class="text-red-500">
                                <div v-if="v$.businessProcess.zipCode.required.$invalid" class="error-text text-xs">Required</div>
                                <div v-else-if="v$.businessProcess.zipCode.required && v$.businessProcess.zipCode.zip.$invalid" class="error-text text-xs">Zip Code is Invalid</div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="fixed flex w-full bottom-5 right-0 bg-white rounded p-5 justify-end" v-if="callsComplete==true">
                <button :disabled="clickOnButton" :class="disableStatus ? 'flex btndisabled pointer-events-none mr-2' : 'flex btnprimary mr-2'" @click="onCancelBtn()">Cancel</button>
                <button :disabled="clickOnButton" v-if="this.$route.path.includes('addEntity')" :class="disableStatus ? 'flex btndisabled pointer-events-none mr-2' : 'flex btnprimary mr-2'" @click="onSaveNewEntity()">Save</button>
                <button :disabled="clickOnButton" v-if="this.$route.path.includes('editEntity')" :class="disableStatus ? 'flex btndisabled pointer-events-none mr-2' : 'flex btnprimary mr-2'" @click="onUpdateEntity()">Update</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.controllabel{
    font-size: 12px;
}
.labelMarginTop{
    margin-top:8px;
}
.taskStatusOptions{
  background-color: white;
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
 .taskStatusOptionsForNotStarted{
    background-color: #e9e9e9;
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
</style>

<script lang="ts">
import { defineComponent } from 'vue';
// import { mapGetters } from 'vuex';
// import newTable from '@/components/newTable.vue';
// import popup from '@/components/popup.vue'
// ^[1-9][0-9]{6}*$
import { emitter, toast } from '@/main';
// import Multiselect from '@vueform/multiselect';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
const zip = helpers.regex(/^\d{5}(?:\d{1})?$/);
const textOnly = helpers.regex(/^[a-zA-Z\s]*$/);
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import breadCrumbs from '@/components/breadCrumbs.vue';
// import { zip } from 'lodash';

export default defineComponent({
    data():any {
        return {
            v$: useVuelidate(),
            name: 'BusinessEntity',
            entityId: '',
            singleEntity: [],
            businessProcess: {
                entityName: '',
                entityDisplayName: '',
                region: '',
                entityType: '',
                owner: '',
                industrySectorName: '',
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                country: '',
                zipCode: ''
            },
            dummyBusinessProcess: {},
            entitesDataArray: [],
            regionList:[],
            entityTypeObject:[
                {
                    id:1,
                    value:'Primary'
                },
                {
                    id:2,
                    value:'Subsidary'
                },
                {
                    id:3,
                    value:'Secondary'
                }
            ],
            users:[],
            optionsUserArray:[],
            OriginalEntities:[],
            filteredObject:{},
            routesArray: [],
            presentEntity : '',
            presentRegion : {},
            presentRegionName : '',
            disablePrimaryEntity : false,
            regionOptions : [],
            userOptions : [],
            existingEntityArray : [],
            entityNames : [],
            entityDisplayNames : [],
 
            callsComplete:false,
            clickOnButton: false,
            searchValue :'',
        };
    },
    components:{
        Multiselect,
        breadCrumbs
    },
    computed: {
        disableStatus: function (): any {
            const deepTrim:any = (obj:any) => {
            if (typeof obj === 'string') {
                return obj.trim();
            } else if (Array.isArray(obj)) {
                return obj.map(deepTrim);
            } else if (typeof obj === 'object' && obj !== null) {
                return Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [key, deepTrim(value)])
                );
            }
            return obj; // Return the value as is if it's neither a string nor an object
            };
        
            const trimmedBusinessProcess = deepTrim(this.businessProcess);
            const trimmedDummyBusinessProcess = deepTrim(this.dummyBusinessProcess);
        
            return JSON.stringify(trimmedBusinessProcess) === JSON.stringify(trimmedDummyBusinessProcess);
        }
    },
    validations() {
        return {
            businessProcess: {
                entityName: { required },
                entityDisplayName: { required },
                owner: { required },
                //industrySectorName: { required },
                entityType: {required},
                region:{required},
                addressLineOne: { required },
                // addressLineTwo: { required },
                city: { required,textOnly  },
                state: { required ,textOnly  },
                country: { required ,textOnly  },
                zipCode: { required, zip }
            }
        };
    },
    methods: {
        async checkCreateRegion(){
            console.log('khsc',this.businessProcess.region)
            var pattern = /^[a-zA-Z]+$/;
            console.log('askldnak',pattern.test(this.businessProcess.region))
            
            if(pattern.test(this.businessProcess.region) == true){
                console.log('Create New Region')
                let payload = {
                    region: this.businessProcess.region,
                    regionHeadQuaters:'',
                    regionOwner:'',
                }
                await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/region/create`, payload).then(async (res: any) => {
                    console.log('sucesssss')
                    toast.info('saved', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })
                }).catch(
                    toast.error('saved', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    })
                )
                return true;
            }else{
                console.log('Dont Create New Region')
                return false;
            }
        },
        filterOptions(option:any, search:any) {
            // Check if the search term is a single character
            if (search.length === 1) {
                return option.name.toLowerCase().startsWith(search.toLowerCase());
            }
            return true; // Show all options for other lengths
       },
        // checkEntityName(entityTitle: any) {
        //     console.log('!@',entityTitle)
        //  const trimmedEntityTitle = entityTitle.trim().toLowerCase();;
        //  let array: any
        //  if(Object.keys(this.$route.params).includes('entityId')){
        //      let originalObj = this.existingEntityArray.find((obj: any)=>{return obj._id == this.$route.params.entityId})
        //      array = this.entityNames.filter((obj: any)=>{
        //          return obj != originalObj.entityName
        //      })
        //         console.log('ARRAY',array)
        //      const trimmedCheckedTitle = entityTitle.trim();
        //      // let check: any = array.includes(trimmedCheckedTitle)
        //         const check: any = array.map((name: any) => name.toLowerCase()).includes(trimmedCheckedTitle);
        //      return check;
        //  }else if(this.$route.path == '/addEntity' || Object.keys(this.$route.query).includes('addAtRegions')){
        //      // let check: any = this.entityNames.includes(trimmedEntityTitle)
        //          const check: any = this.entityNames.map((name: any) => name.toLowerCase()).includes(trimmedEntityTitle);
        //      return check;
        //  }
        // },
        checkEntityName(entityTitle: any) {
            const trimmedEntityTitle = entityTitle.trim().toLowerCase(); // Convert to lowercase
            let array: any;
           
            if (Object.keys(this.$route.params).includes('entityId')) {
                let originalObj = this.existingEntityArray.find((obj: any) => obj._id == this.$route.params.entityId);
               
                array = this.entityNames.filter((obj: any) => obj.toLowerCase() != originalObj.entityName.toLowerCase()); // Filter out the original name, ignoring case
               
               
                const check: any = array.map((name: any) => name.toLowerCase()).includes(trimmedEntityTitle); // Compare case-insensitively
                return check;
            } else if (this.$route.path == '/addEntity' || Object.keys(this.$route.query).includes('addAtRegions')) {
                const check: any = this.entityNames.map((name: any) => name.toLowerCase()).includes(trimmedEntityTitle); // Compare case-insensitively
                return check;
            }
         },
        // checkDisplayEntityName(entityDisplayTitle: any) {
        //  const trimmedentityDisplayTitle = entityDisplayTitle.trim();
        //  let array: any
        //  if(Object.keys(this.$route.params).includes('entityId')){
        //      let originalObj = this.existingEntityArray.find((obj: any)=>{return obj._id == this.$route.params.entityId})
        //      array = this.entityDisplayNames.filter((obj: any)=>{
        //          return obj != originalObj.entityDisplayName
        //      })
        //         console.log('ARRAY',array)
        //      const trimmedCheckedTitle = entityDisplayTitle.trim();
        //      let check: any = array.includes(trimmedCheckedTitle)
        //         // if(array != undefined || array != null){
        //         //   const check: any = array.find((name: any) => name.toLowerCase()).includes(trimmedCheckedTitle);
        //         //       return check;
        //         // }
        //         return check;
        //  }else if(this.$route.path == '/addEntity' || Object.keys(this.$route.query).includes('addAtRegions')){
        //      // let check: any = this.entityDisplayNames.includes(trimmedentityDisplayTitle)
        //          const check: any = this.entityDisplayNames.map((name: any) => name.toLowerCase()).includes(trimmedentityDisplayTitle);
        //      return check;
        //  }
        // },
        checkDisplayEntityName(entityDisplayTitle: any) {
            const trimmedentityDisplayTitle = entityDisplayTitle.trim().toLowerCase(); // Convert to lowercase
            let array: any;
            
            if (Object.keys(this.$route.params).includes('entityId')) {
                let originalObj = this.existingEntityArray.find((obj: any) => obj._id === this.$route.params.entityId);
                
                array = this.entityDisplayNames.filter((obj: any) => obj.toLowerCase() !== originalObj.entityDisplayName.toLowerCase()); // Filter out the original name, ignoring case
                
                
                const check: any = array.map((name: any) => name.toLowerCase()).includes(trimmedentityDisplayTitle); // Compare case-insensitively
                return check;
            } else if (this.$route.path === '/addEntity' || Object.keys(this.$route.query).includes('addAtRegions')) {
                const check: any = this.entityDisplayNames.map((name: any) => name.toLowerCase()).includes(trimmedentityDisplayTitle); // Compare case-insensitively
                return check;
            }
        },
        async onSaveNewEntity() {
            let create = await this.checkCreateRegion();
                console.log('A',this.businessProcess)

            this.v$.businessProcess.$touch();
            if (!this.v$.businessProcess.$invalid && !this.checkEntityName(this.businessProcess.entityName) && !this.checkDisplayEntityName(this.businessProcess.entityDisplayName)) {  
                this.clickOnButton = true;
                let myObject:any = {...this.businessProcess}
               
                 if(create == true){
                    await this.getAllRegions();
                    let a = this.regionList.find((obj:any)=>{return obj.label == myObject.region});
                    myObject.region = a.value;
                }
                myObject.owner = myObject.owner.value
                myObject.region = myObject.region.value
 
                try{
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntity/create`, myObject).then((res: any) => {
                        toast.info('Saved', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        if(this.$route.path == '/addEntity'){
                            this.$router.push({name:'businessEntites'})
                        }else if(Object.keys(this.$route.params).includes('regionId')){
                            this.$router.push({name:'businessEntitesForRegion'})
                        }
                    })
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }
               
            }
            // else{
            //     toast.error(`cannot save`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        // asyncFindForRegions(query:any){
        //     if(query == ''){
        //         this.regionOptions = []
        //     }else if(query.length > 1){
        //         this.regionOptions = this.regionList.filter((obj:any)=>{
        //             if(obj.label != null){
        //                return obj.label.toLowerCase().includes(query.toLowerCase())
        //             }else if(obj.label == null){
        //                 obj.label == 'N/A'
        //             }
        //             })
        //     }
        // },
            asyncFind(query:any){
            this.searchValue = query;
            if(query == ''){
                this.userOptions = []
            }else if(query.length > 1){
                this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
            }
        },
        async getAllbusinessEntities(){
             await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                console.log("JYOTHHH",res.data)
                this.existingEntityArray = res.data;
                this.entityNames = [];
                this.existingEntityArray.forEach((name: any)=>{
                    this.entityNames.push(name.entityName)
                })
                this.entityDisplayNames = []
                 res.data.forEach((name: any)=>{
                    this.entityDisplayNames.push(name.entityDisplayName)
                })
                  res.data.map((obj:any)=>{
                    if(obj.entityType == 1){
                        this.disablePrimaryEntity = true
                    }
                })
             });
        },
        async onUpdateEntity(){
            // let create = await this.checkCreateRegion();
            // if(create == true){
            //     await this.getAllRegions();
            //     let a = this.regionList.find((obj:any)=>{return obj.label == this.businessProcess.region});
            //     this.businessProcess.region = a.value;
            // }
            this.v$.businessProcess.$touch();
            if (!this.v$.businessProcess.$invalid && !this.checkEntityName(this.businessProcess.entityName) && !this.checkDisplayEntityName(this.businessProcess.entityDisplayName)) {
                this.clickOnButton = true;
                let myObject:any = {...this.businessProcess};
               
                console.log("this.businessProcess", myObject)
                delete myObject._id
                myObject.owner = myObject.owner.value;
                myObject.region = myObject.region.value;
 
 
                try {
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntity/${this.entityId}/update`, myObject).then(async () => {
                        await this.getSingleEntity();
                        toast.info(`Updated`, {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        if(Object.keys(this.$route.params).includes('regionId')){
                            this.$router.push({name:'businessEntitesForRegion'})
                        }else{
                            this.$router.push({name:'businessEntites'})
                        }
                    });
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }
 
            }
            //  else{
            //     toast.error(`Error`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        onCancelBtn(){
            this.v$.$reset();
            if(this.businessProcess._id){
                this.businessProcess = {...this.dummyBusinessProcess};
            } else{
               
                this.businessProcess = {...this.dummyBusinessProcess};               // Object.keys(this.businessProcess).forEach((prop) => {
                //  Array.isArray(this.businessProcess[prop]) ? (this.businessProcess[prop] = []) : (this.businessProcess[prop] = '');
                // });
            }
        },
         async getAllRegions(){
            this.regionList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
                res.data.map((obj:any)=>{
                    console.log('I',obj)
                    let a = { value:obj._id, label:obj.region }
                    this.regionList.push(a);
                    console.log('!@#$%^&*()__',this.regionList)
                    this.regionList.map((obj:any)=>{
                       if(this.$route.params.regionId == obj.value){
                        this.presentRegion = obj
                        this.presentRegionName = obj.label
                           console.log('hhh',this.presentRegion)
                     }
                    })
                })
            })
        },
        async getSingleEntity(){
          if(this.$route.path.includes('editEntity')){
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntity/${this.entityId}/get`).then((res: any) => {
                this.singleEntity = res.data;
                console.log('qwee',this.singleEntity)
                // this.singleEntity.map((obj:any)=>{
                //     if(obj.entityType == 1){
                //         this.disablePrimaryEntity = true
                //     }
                if(this.entityId == this.singleEntity[0]._id){
                    this.businessProcess = this.singleEntity[0]
                    var editEntityOwner = this.optionsUserArray.find((obj:any)=>{
                       return obj.value == this.businessProcess.owner
                    });
                    this.businessProcess.owner = editEntityOwner
 
                    var editEntityRegion = this.regionList.find((obj:any)=>{
                       return obj.value == this.businessProcess.region
                    });
                        this.businessProcess.region = editEntityRegion
                    this.presentEntity =  this.businessProcess != undefined ? this.businessProcess.entityName : ''
                    console.log('123',this.presentEntity)
 
                }
 
                this.dummyBusinessProcess = {...this.businessProcess}
            });
        }
        },
        async getAllActiveUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
           
        },
    },
    async created(){
        await this.getAllActiveUsers();
        await this.getAllRegions();
        await this.getSingleEntity();
        await this.getAllbusinessEntities();
        if(Object.keys(this.$route.params).includes('regionId')){
            if(this.presentRegion != undefined && this.presentRegion.label != null){
                this.businessProcess.region = this.presentRegion
            }else{
                this.businessProcess.region = ''
            }
        }
        this.dummyBusinessProcess = {...this.businessProcess};
 
        this.callsComplete = true;
 
        if(Object.keys(this.$route.query).includes('entity')){
            this.routesArray = [
                { name: 'Regions', routeName: 'regions' },
                { name: this.presentRegionName!=undefined ? this.presentRegionName : 'N/A', routeName: 'businessEntitesForRegion' },
                { name: 'Edit Entity', routeName: '' },
            ];
        }else if(this.$route.path.includes('/editEntity') && (!Object.keys(this.$route.query).includes('entity'))){
            this.routesArray = [
                { name: 'Entities', routeName: 'businessEntites' },
                { name:'Edit Entity', routeName: '' }
            ];
        }
 
        if(Object.keys(this.$route.query).includes('addAtRegions')){
            this.routesArray = [
                { name: 'Regions', routeName: 'regions' },
                { name: this.presentRegionName!=undefined ? this.presentRegionName : 'N/A', routeName: 'businessEntitesForRegion' },
                { name: 'Add Entity', routeName: '' },
            ];
        }if(this.$route.path.includes('/addEntity') && (!Object.keys(this.$route.query).includes('addAtRegions'))){
            this.routesArray = [
                    { name: 'Entities', routeName: 'businessEntites' },
                { name:'Add Entity', routeName: '' }
            ];
        }
    },
    async mounted(){
        if(this.$route.path.includes('editEntity')){
            console.log("routeId", this.$route.params.entityId)
            this.entityId = this.$route.params.entityId
        }
        // await this.getAllEntities();
        // this.dummyBusinessProcess = {...this.businessProcess}
         

    }
});
</script>
